import 'vite/modulepreload-polyfill';

import '@maison/styles';

import './sentry';
import Outdated from './components/Outdated';
import LazyPopover from './components/LazyPopover';
import LoadMore from './components/LoadMore';
import SectionTracker from './components/SectionTracker';
import GoogleTracking from './components/tracking/GoogleTracking';
import SensorsDataTracking from './components/tracking/SensorsDataTracking';
import Notification from './components/Notification';
import * as Cookies from 'js-cookie';
import laravelRoute from 'ziggyRoute';
import store from '@phoenix/store';

// Maison related imports
import * as Toolkit from '@maison/Toolkit';

new Outdated();
new LoadMore();
new SectionTracker('.rcms_anchor');
const tracking = window.Phoenix.trackingVariables.isSensorsDataActivated
  ? new SensorsDataTracking()
  : new GoogleTracking();
Phoenix.notification = new Notification();

Object.assign(window, {
  route: laravelRoute,
  tracking,
  Toolkit,
  LazyPopover,
  Cookies,
});

document.addEventListener('DOMContentLoaded', () => {
  store.dispatch('currentUser/loadCustomerInfo');
});
