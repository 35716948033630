import { clientApi } from '@phoenix/clientApi';

export default class LazyPopover {
  el: HTMLElement;
  url: string;
  didLoadContent: boolean;

  constructor(selector: string, url: string) {
    this.el = document.querySelector(selector);
    this.url = url;
    this.bindEvents();
  }

  bindEvents() {
    this.el.addEventListener('click', this.loadPopoverContent.bind(this));
  }

  async loadPopoverContent() {
    if (this.didLoadContent) {
      return;
    }

    const popoverBody = this.el.querySelector('.popover__body');
    const t = new Date().getTime();
    const response = await clientApi.get(this.url, { params: { t } });
    popoverBody.innerHTML = response.data;
    this.didLoadContent = true;
  }
}
