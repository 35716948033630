import { Collapse, Stickyfill } from '@maison/Toolkit';

export default class Outdated {
  constructor() {
    // Only IE with version 10 or lower have MSIE in there User Agent string.
    // Only IE with version 11 has Trident in it's User Agent string.
    // source: https://codepen.io/gapcode/pen/vEJNZN
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > 0 || window.navigator.userAgent.indexOf('Trident/') > 0;

    if (isIE) {
      const outdatedBlock = document.querySelector('#outdated');
      if (outdatedBlock) {
        outdatedBlock.classList.remove('hidden');
        (<any>window).Phoenix.promotionBlockCollapsible = new Collapse(outdatedBlock, {
          onHidden() {
            // Refresh the navigation position after the promotion's block closed
            Stickyfill.refreshAll();
          },
        });
        (<any>window).Phoenix.promotionBlockCollapsible.show();
      }
    }
  }
}
