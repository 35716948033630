import * as Cookie from 'js-cookie';

export default class Notification {
  name: string;

  constructor(name = 'notifications') {
    this.name = name;

    document.addEventListener('DOMContentLoaded', () => {
      this.displayNotifications();
    });
  }

  displayNotifications() {
    const notifications = Cookie.get(this.name);
    if (notifications) {
      JSON.parse(notifications).forEach((notification) => {
        // FIXME: Remove "?" when Toaster is ready
        Phoenix.toaster?.notify({
          type: notification.type,
          text: notification.text,
        });
      });
      Cookie.remove(this.name);
    }
  }

  create(text, type) {
    Cookie.set(this.name, [{ type, text }]);
  }
}
