export default class LoadMore {
  constructor() {
    Array.from(document.querySelectorAll('[data-load-more-step]')).forEach(this.display);

    Array.from(document.querySelectorAll('[data-load-more-target]')).forEach((button: HTMLElement) => {
      const id = button.getAttribute('data-load-more-target');
      const container = document.querySelector(`[data-load-more-id="${id}"]`);
      button.addEventListener('click', () => {
        this.display(container);
      });
    });
  }

  display = (container) => {
    // we add a bit of pixels to avoid possible rounding issues
    const containerWidth = Math.ceil(container.clientWidth) + 20;
    const steps = +container.getAttribute('data-load-more-step') || 2;
    const items = Array.from(container.children);
    const currentIndex = +container.getAttribute('data-load-more-current') || 0;
    items.forEach((el: HTMLElement) => {
      el.classList.remove('force-hidden');
    });

    let currentWidth = 0;
    let linesCount = 0;
    let lastIndexToShow = 0;
    items.forEach((el: HTMLElement) => {
      currentWidth += Math.floor(el.offsetWidth);

      if (currentWidth > containerWidth) {
        currentWidth = Math.floor(el.offsetWidth);

        if (lastIndexToShow > currentIndex) {
          linesCount += 1;
        }
      }

      if (linesCount < steps) {
        lastIndexToShow += 1;
      }
    });

    if (containerWidth === 20) {
      // Change this later. It's for the case where a grid is in a hidden container.
      lastIndexToShow = lastIndexToShow < 6 ? lastIndexToShow : 6;
    }

    container.setAttribute('data-load-more-current', lastIndexToShow);

    items.slice(lastIndexToShow).forEach((el: HTMLElement) => {
      el.classList.add('force-hidden');
    });

    if (lastIndexToShow === items.length) {
      document
        .querySelector(`[data-load-more-target="${container.getAttribute('data-load-more-id')}"]`)
        .classList.add('force-hidden');
    }
  };
}
