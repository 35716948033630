import PhoenixSentry from '@phoenix/PhoenixSentry';

window.Phoenix.sentry = new PhoenixSentry({
  env: window.Phoenix.config.ENV,
  user: null,
  sentryUrl: window.Phoenix.config.SENTRY_URL,
  appName: window.Phoenix.config.APP_NAME,
  dataCenter: window.Phoenix.config.DATACENTER,
  release: window.Phoenix.config.RELEASE,
  messagesToIgnore: window.Phoenix.config.SENTRY_MESSAGES_TO_IGNORE,
  urlsToIgnore: window.Phoenix.config.SENTRY_URLS_TO_IGNORE,
});
