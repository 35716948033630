import { scrollToElement } from '@maison/helpers/common-helper';

interface IAccordionItemFromToolkit {
  _group: string;
  _options?: any;
  anchor: string;
}

export const getAllCollapsibleFromAccordionID = (accordionID: string, collapsibleClass: string) =>
  document.querySelectorAll(`#${accordionID} .${collapsibleClass}`);

export const instantiateHashmapOfCollapsible = (collapsibles: NodeListOf<Element>, toolkitClass) => {
  const hashmap = new Map<string, IAccordionItemFromToolkit>();
  collapsibles.forEach((collapsible) =>
    hashmap.set(collapsible.id, {
      ...new toolkitClass(collapsible),
      anchor: collapsible.attributes['data-anchor']?.textContent ?? '',
    })
  );
  return hashmap;
};

export const getAccordionItemIdFromAnchor = (hashmapOfCollapsible: Map<string, IAccordionItemFromToolkit>) => {
  const locationToShow = window.location.hash.substring(1);
  if (!locationToShow) return;

  for (const entry of Array.from(hashmapOfCollapsible.entries())) {
    const key = entry[0];
    const value = entry[1];
    if (value.anchor === locationToShow) {
      return key;
    }
  }
};

export const openAccordionItem = (
  id: string,
  collapsibles: Map<string, IAccordionItemFromToolkit>,
  collapseMethod: (item: IAccordionItemFromToolkit) => void,
  idPrefix = 'accordion-item-'
) => {
  if (!collapsibles.get(id)) {
    return;
  }

  const element = document.getElementById(id);
  const item = collapsibles.get(id);
  let parentId: string;

  if (item._group) {
    // Legacy behaviour
    parentId = collapsibles.get(item._group) ? item._group : `${idPrefix}${item._group.split('-').reverse()[0]}`;
  } else {
    // If no group is defined, search for a parent accordion-item
    parentId = element.parentElement?.closest(`[id^="${idPrefix}"]`)?.id;
  }

  if (parentId) {
    // Recursive call to open all parent accordion
    openAccordionItem(parentId, collapsibles, collapseMethod);
  }

  // We expand only after the recursive call
  // So it's like a normal user interaction
  collapseMethod(item);

  setTimeout(() => {
    // Scrolling to the parent element allows us to see the question/title
    const target = element.parentElement || element;
    // Similar to scrollIntoView, but it takes the navbar in consideration
    scrollToElement(target, 'smooth', false);
  }, 300); // wait for items to expand - the CSS transition takes 250ms
};

export const openAccordionItemFromAnchor = (
  accordionID: string,
  accordionClass: string,
  toolkitJSClass: any,
  collapseMethod: (item: IAccordionItemFromToolkit) => void
) => {
  const collapsibles = instantiateHashmapOfCollapsible(
    getAllCollapsibleFromAccordionID(accordionID, accordionClass),
    toolkitJSClass
  );
  openAccordionItem(getAccordionItemIdFromAnchor(collapsibles), collapsibles, collapseMethod);
};
