export default class SectionTracker {
  sections: {};

  /**
   * @param {string} anchorCSSSelector CSS selector of the anchors located before the sections.
   */
  constructor(anchorCSSSelector: string) {
    this.sections = this.retrieveSections(anchorCSSSelector);
    this.handleScroll = this.handleScroll.bind(this);
    this.addEventListeners();
  }

  /**
   * Listen for scroll and track when user reach a block section.
   */
  addEventListeners() {
    window.addEventListener('scroll', this.handleScroll);
  }

  removeEventListeners() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    window.requestAnimationFrame(this.trackBlockSection.bind(this));
  }

  /**
   * Push a virtualPageview when the user reach each section for the first time on the page.
   */
  trackBlockSection() {
    for (const name of Object.keys(this.sections)) {
      if (this.isVisibleInViewPort(this.sections[name])) {
        dataLayer.push({
          event: 'contentReached',
          block: `${window.location.pathname}#${name}`,
        });
        delete this.sections[name];
      }
    }

    if (!Object.keys(this.sections)) {
      this.removeEventListeners();
    }
  }

  /**
   * Return true whether the given section is visible on the screen.
   *
   * @param {Element} section
   * @returns {boolean}
   */
  isVisibleInViewPort(section: Element) {
    const rect = section.getBoundingClientRect();
    return (rect.top > 0 && rect.top < window.innerHeight) || (rect.top < 0 && rect.bottom > 0);
  }

  /**
   * Grab all sections that have an anchor tag as previous sibling.
   * @param {string} anchorCSSSelector
   * @returns {}
   */
  retrieveSections(anchorCSSSelector: string) {
    const anchors = document.querySelectorAll(anchorCSSSelector);

    const sections = {};

    [].forEach.call(anchors, (anchor) => {
      const sibling = anchor.nextElementSibling;

      const name = anchor.getAttribute('name');

      if (name && sibling && sibling.classList.contains('section')) {
        sections[name] = sibling;
      }
    });

    return sections;
  }
}
